exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livros-beeks-json-book-code-js": () => import("./../../../src/pages/livros/{beeksJson.book_code}.js" /* webpackChunkName: "component---src-pages-livros-beeks-json-book-code-js" */),
  "component---src-pages-pessoas-peeps-json-person-code-js": () => import("./../../../src/pages/pessoas/{peepsJson.person_code}.js" /* webpackChunkName: "component---src-pages-pessoas-peeps-json-person-code-js" */),
  "component---src-templates-categoria-js": () => import("./../../../src/templates/categoria.js" /* webpackChunkName: "component---src-templates-categoria-js" */),
  "component---src-templates-livros-list-js": () => import("./../../../src/templates/livros-list.js" /* webpackChunkName: "component---src-templates-livros-list-js" */),
  "component---src-templates-pessoas-list-js": () => import("./../../../src/templates/pessoas-list.js" /* webpackChunkName: "component---src-templates-pessoas-list-js" */),
  "component---src-templates-setor-js": () => import("./../../../src/templates/setor.js" /* webpackChunkName: "component---src-templates-setor-js" */),
  "component---src-templates-top-template-js": () => import("./../../../src/templates/top_template.js" /* webpackChunkName: "component---src-templates-top-template-js" */)
}

